import { Container } from 'postcss'
import React from 'react'
import GraphLulingTS from '../components/plots/PlotLuling'
// import Spinner from '../components/layout/Spinner'

function PlotScreenLuling() {

  const today = new Date()
  const start = new Date(today)
  
  start.setDate(start.getDate() - 5)
  today.setDate(today.getDate() + 1)
  // today.toISOString()
  // yesterday.toISOString()
  // console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
  const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
  const end_time = today.toISOString().split('T')[0] + ' 00:00:00'


  return (
    <div>
      <h1 className='text-3xl'>Luling Bridge</h1>
      <h2 className='bg-slate-300 text-2xl'>Luling System No.1</h2>

      <h3>Strains</h3>
      <GraphLulingTS gagelist= {{
        "primary" : [ 

          {"gage":"sys1_loc1_strain_c1","name":"S1_L1_C1","table":"L1","tare":1680.5,"scalar":1.0},
          {"gage":"sys1_loc1_strain_c2","name":"S1_L1_C2","table":"L1","tare":1720,"scalar":1.0},
          {"gage":"sys1_loc1_strain_e","name":"S1_L1_E","table":"L1","tare":3317.8,"scalar":1.0},
          {"gage":"sys1_loc1_strain_s","name":"S1_L1_S","table":"L1","tare":335.5,"scalar":0.667},
        ] ,
       "y_range": [0, 70],"title":"Location 1 Strain","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L1'} />

<GraphLulingTS gagelist= {{
        "primary" : [ 


          {"gage":"sys1_loc2_strain_c1","name":"S1_L2_C1","table":"L1","tare":1371.5,"scalar":1.0},
          {"gage":"sys1_loc2_strain_c2","name":"S1_L2_C2","table":"L1","tare":868.5,"scalar":1.0},
          {"gage":"sys1_loc2_strain_e","name":"S1_L2_SE","table":"L1","tare":1173.55,"scalar":1.},
          {"gage":"sys1_loc2_strain_s","name":"S1_L2_S","table":"L1","tare":-271.2,"scalar":0.667},
        ] ,
       "y_range": [0, 70],"title":"Location 2 Strains","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L1'} />

      <h3>Temperatures</h3>
<GraphLulingTS gagelist= {{
        "primary" : [ 
          {"gage":"sys1_temp_c_c1","name":"S1_F_C1","table":"L1","tare":-32,"scalar":1.8},
          {"gage":"sys1_temp_c_c2","name":"S1_F_C2","table":"L1","tare":-32,"scalar":1.8},
          {"gage":"sys1_temp_c_e","name":"S1_F_E","table":"L1","tare":-32,"scalar":1.8},
          {"gage":"sys1_temp_c_s","name":"S1_F_S","table":"L1","tare":-32,"scalar":1.8},
        ] ,
       "y_range": [0, 70],"title":"Location 2 Temps","y_axis_label":"Temperature (F)"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L1'} />

<h2 className='bg-slate-300 text-3xl'>Luling System No.2</h2>

<h3 className='text-2xl'>Strains</h3>
  <GraphLulingTS gagelist= {{
    "primary" : [  
      {"gage":"sys2_loc1_strain_c1","name":"S2_L1_C1","table":"L2","tare":2198.5,"scalar":1.0},
      {"gage":"sys2_loc1_strain_c2","name":"S2_L1_C2","table":"L2","tare":1857.3,"scalar":1.0},
      {"gage":"sys2_loc1_strain_e","name":"S2_L1_E","table":"L2","tare":2899.7,"scalar":1.0},

    ] ,
  "y_range": [0, 70],"title":"Location 1 Strains","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L2'} />

<GraphLulingTS gagelist= {{
    "primary" : [ 
      {"gage":"sys2_loc2_strain_c1","name":"S2_L2_C1","table":"L2","tare":2326.5,"scalar":1.0},
      {"gage":"sys2_loc2_strain_c2","name":"S2_L2_C2","table":"L2","tare":2724.3,"scalar":1.0},
      {"gage":"sys2_loc2_strain_e","name":"S2_L2_E","table":"L2","tare":9332.8,"scalar":1.0},
    ] ,
  "y_range": [0, 70],"title":"Location Strains","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L2'} />

      <h3>Temperatures</h3>
<GraphLulingTS gagelist= {{
        "primary" : [ 
          {"gage":"sys2_temp_c_c1","name":"S1_F_C1","table":"L2","tare":-32,"scalar":1.8},
          {"gage":"sys2_temp_c_c1","name":"S1_F_C2","table":"L2","tare":-32,"scalar":1.8},
          {"gage":"sys2_temp_c_e","name":"S1_F_E","table":"L2","tare":-32,"scalar":1.8},
          {"gage":"sys2_temp_c_a","name":"S1_F_A","table":"L2","tare":-32,"scalar":1.8},
        ] ,
       "y_range": [0, 70],"title":"Location 2 Temps","y_axis_label":"Temperature (F)"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L2'} />

<h2 className='bg-slate-300 text-2xl'>Luling System No.3</h2>
<h3>Strains</h3>

<GraphLulingTS gagelist= {{
    "primary" : [ 
      {"gage":"sys3_loc1_strain_c1","name":"S3_L1_C1","table":"L3","threshold":[-10000,10000.0],"tare":1544.23,"scalar":1.0},
      {"gage":"sys3_loc1_strain_c2","name":"S3_L1_C2","table":"L3","threshold":[-10000,10000.0],"tare":432.8,"scalar":1.0},
      {"gage":"sys3_loc1_strain_e","name":"S3_L1_E","table":"L3","threshold":[-10000,10000.0],"tare":4786.05,"scalar":1.0},
      {"gage":"sys3_loc1_strain_s","name":"S3_L1_S","table":"L3","threshold":[-10000,10000.0],"tare":-206.4,"scalar":0.667},
    ] ,
  "y_range": [0, 70],"title":"Location 1 Strain","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L3'} />
<GraphLulingTS gagelist= {{
    "primary" : [ 
      {"gage":"sys3_loc1_strain_c1","name":"S3_L2_C1","table":"L3","threshold":[-10000,10000.0],"tare":2242.87,"scalar":1.0},
      {"gage":"sys3_loc1_strain_c2","name":"S3_L2_C2","table":"L3","threshold":[-10000,10000.0],"tare":1288.2,"scalar":1.0},
      {"gage":"sys3_loc2_strain_e","name":"S3_L2_E","table":"L3","threshold":[-10000,10000.0],"tare":732.8,"scalar":1.0},
      {"gage":"sys3_loc2_strain_s","name":"S3_L2_S","table":"L3","threshold":[-10000,10000.0],"tare":-534.9,"scalar":0.667},
    ] ,
  "y_range": [0, 70],"title":"Location 2 Strain","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L3'} />

  <GraphLulingTS gagelist= {{
    "primary" : [ 
      {"gage":"sys3_loc3_strain_s","name":"S3_L3_S","table":"L3","threshold":[-10000,10000.0],"tare":0.0,"scalar":0.667},
    ] ,
  "y_range": [0, 70],"title":"Location 3 Strain","y_axis_label":"Strain"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L3'} />

<h3>Temperatures</h3>

  <GraphLulingTS gagelist= {{
        "primary" : [ 

          {"gage":"sys3_temp_c_s","name":"S3_F_S","table":"L3","tare":-32,"scalar":1.8},
          {"gage":"sys3_temp_c_e","name":"S3_F_E","table":"L3","tare":-32,"scalar":1.8},
          {"gage":"sys3_temp_c_c1","name":"S3_F_C1","table":"L3","tare":-32,"scalar":1.8},
          {"gage":"sys3_temp_c_c2","name":"S3_F_C2","table":"L3","tare":-32,"scalar":1.8},
        ] ,
       "y_range": [0, 70],"title":"Location 2 Temps","y_axis_label":"Temperature (F)"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'L2'} />


   
    </div>
  )


}

export default PlotScreenLuling
